/* import '../src/assets/q'
import '../src/assets/LargeLocalStorage'

var desiredCapacity = 125 * 1024 * 1024;

var storage = new LargeLocalStorage({size: desiredCapacity, name: 'myDb'});

// Await initialization of the storage area
storage.initialized.then(function(grantedCapacity) {
  // Check to see how much space the user authorized us to actually use.
  // Some browsers don't indicate how much space was granted in which case
  // grantedCapacity will be 1.
  if (grantedCapacity != -1 && grantedCapacity != desiredCapacity) {
  }
}); */

import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App.vue'
import {router} from './routes'
import store  from './store'
import { BootstrapVue, BootstrapVueIcons, ModalPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './assets/loading.css'


Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(ModalPlugin)


/* import VueResource from 'vue-resource' */
import Vuelidate from 'vuelidate'
import VueRouter from 'vue-router'
Vue.use(Vuelidate)
Vue.use(VueRouter)



import moment from 'moment';
import VueMoment from 'vue-moment';
require('moment/locale/tr');
moment.locale('tr');
Vue.use(VueMoment, { moment });

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);


import VuejsDialog from 'vuejs-dialog';

import 'vuejs-dialog/dist/vuejs-dialog.min.css';
Vue.use(VuejsDialog);
import axios from "axios"
window.axios = require('axios');
axios.defaults.baseURL = "https://ws.monoryum.com"
//axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

axios.interceptors.request.use(function (config) {


   if(config.url != "/token"){
      const token = store.state.userToken;
     
      config.headers.Authorization = "Bearer " + token;
      //config.headers.Accept = "*/*";
       
    }

  console.log(config)
  return config;
});

import Zondicon from 'vue-zondicons';

Vue.component('Zondicon', Zondicon);


import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect)
import 'vue-multiselect/dist/vue-multiselect.min.css'

import 'vue-instant/dist/vue-instant.css'
import VueInstant from 'vue-instant/dist/vue-instant.common'
Vue.use(VueInstant)
import CKEditor from 'ckeditor4-vue'
Vue.use(CKEditor);

new Vue({
  el: '#app',
  store,
  router,
   
  render: h => h(App)
})
