



import Vue from "vue"
import Vuex from "vuex"


import { router } from './routes.js'
import createPersistedState from "vuex-persistedstate";
import axios from "axios";
Vue.use(Vuex)


import { BootstrapVue, BootstrapVueIcons, ModalPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


import './assets/loading.css'


Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(ModalPlugin)




// Create a 125MB key-value store




const store = new Vuex.Store({
  state: {
    sideBarComp: '',
    sideBarOpen: false,
    sideBarTitle: '',
    aktifComp: "appHome",
    calisilanSirketId: null,
    loadAnimAc: false,
    postServer: "https://ws.monoryum.com",
    portalContentAdres: "/content/sirketler/sirket_",
    userToken: null,
    userMail: '',
    userSifre: '',
    gecerliLostGuid: false,
    loginGizliAl: false,
    userSirketler: {
      liste: [],
      sirketSayisi: 0
    },
    userModel: {
      id: null,
      ad: null,
      mail: null
    },
    faturalar_hizliDuzenleFatura: null,

    sirketAyarlar: {
      sirket_belgeNoTables: [],
      ililceListesi: [],
      odemePlanlari: [{ value: 0, text: 'Gün' }, { value: 1, text: 'Hafta' }, { value: 2, text: 'Ay' }, { value: 3, text: 'Yıl' }],
      global_kabCinsleri: [],
      tumCariler: [],
      tumFaturalar: [],
      tumStoklar: [],
      markaListesi: [],
      sistemKdvOranlar: []

    },
    entitiler: {
      efatSenaryolar: [
        { value: 0, text: 'Temel Fatura' },
        { value: 1, text: 'Ticari Fatura' },
        { value: 2, text: 'E-Arşiv' },
      ],
      ihracat: {
        gonderimSekilleri: [
          { value: 1, text: 'Gemi Taşımacılığı' },
          { value: 2, text: 'Raylı Taşıma' },
          { value: 3, text: 'Kara Taşımacılığı' },
          { value: 4, text: 'Hava Taşımacılığı' },
          { value: 5, text: 'Posta' },
          { value: 6, text: 'Çok Araçlı' },
          { value: 7, text: 'Sabit Taşıma Tesisleri' },
          { value: 8, text: 'İç Su Taşımacılığı' },
          { value: 9, text: 'Belirtilmedi' }
        ],
        teslimSartlari: [
          { value: 'CFR', text: 'Maliyet + Navlun CFR' },
          { value: 'CIF', text: 'Maliyet,Sigorta ve Navlun CIF' },
          { value: 'CIP', text: 'Taşıma ücreti ve Sigortası Ödenmiş CIP' },
          { value: 'CPT', text: 'Taşıma Ücreti CPT' },
          { value: 'DAF', text: 'Sınırda Teslim DAF' },
          { value: 'DDP', text: 'Gümrük Vergisi Ödenmiş DDP' },
          { value: 'DDU', text: 'Gümrük Vergisi Ödenmemiş DDU' },
          { value: 'DEQ', text: 'Rıhtımda Teslim DEQ' },
          { value: 'DES', text: 'Gemide Teslim DES' },
          { value: 'EXW', text: 'İş Yerinde EXW' },
          { value: 'FAS', text: 'Gemide Yanında Teslim FAS' },
          { value: 'FCA', text: 'Taşıyıcıya Teslim FCA' },
          { value: 'FOB', text: 'Güvertede Teslim FOB' },
          { value: 'DPU', text: 'Belirlenmiş Yerde Boşaltılmış Teslim DPU' }
        ]

      }
    },
    sirketSecAcilsin: false,
    cariTipler: [
      { value: null, text: "" },
      { value: 1, text: "Alıcı" },
      { value: 0, text: "Satıcı" },
    ]

  },

  mutations: {

    clearToken(state) {
      state.userToken = null
      localStorage.removeItem("token")
      localStorage.removeItem("userMail")
      localStorage.removeItem("userSifre")
      localStorage.removeItem("exd")
      localStorage.removeItem("sirketler")
      localStorage.removeItem("calisilanSirketId")
      localStorage.removeItem("userBilgi")
      localStorage.removeItem("sirketAyarlar")
      localStorage.removeItem("portalContentAdres")
      localStorage.removeItem("vuex")
    },
    dashBoardAc() {

      router.push("/dashboard")
    },
    sirketSecAc() {
      router.push("/sirketSec")
    }

  },

  actions: {
    makeToast({ state }, authData) {

      this._vm.$bvToast.toast(authData.mesaj, {
        title: 'BİLGİ',
        autoHideDelay: 5000,
        appendToast: false
      })
    },
    initPage({ dispatch,state }) {

      let token = localStorage.token;
      let userMail = localStorage.userMail
      let userSifre = localStorage.userSifre

      if (token) {

        let exd = localStorage.exd

        let time = new Date().getTime()
        if (+time > +exd) {
          console.log("Token Bitti")
          dispatch("logout")
        } else {


          let kalanSure = +exd - time
//alert("Kalan " + kalanSure + " exd :" +  exd + " time :" + time)
            
          dispatch("otomatikLogout", { expiresIn: +kalanSure })
          dispatch("setUserData", { t: token, u: userMail, p: userSifre });

          state.loadAnimAc = false
          //console.log(router.history.current.path)
          if (router.history.current.path == "/") {
            router.push("/dashboard");
          } else {
            router.push(router.history.current.path);
          }

        }


      } else {
        router.push("/");
        return false
      }

    },
    sirketDegistir({ dispatch, state }, authData) {


      localStorage.calisilanSirketId = authData.id

      state.calisilanSirketId = authData.id;

      //this.state.portalFaturalarAdres += "/sirket_" + authData.id + "/faturalar"
      // localStorage.portalContentAdres = this.state.portalFaturalarAdres + "/sirket_" + authData.id + "/faturalar"
      //router.go("/dashboard")
      state.sirketSecAcilsin = false;
      dispatch("sirket_GenelListeler")

    },
    logout({ commit }) {
      commit("clearToken")

      router.replace("/")
    },
    setUserData({ state }, authData) {


     // alert( localStorage.portalContentAdres)
      state.userToken = authData.t
      state.userMail = authData.u
      state.userSifre = authData.p

      let userBilgi = JSON.parse(localStorage.userBilgi)

      state.userModel.id = userBilgi.id
      state.userModel.ad = userBilgi.ad
      state.userModel.mail = userBilgi.maili

      state.calisilanSirketId = localStorage.calisilanSirketId

      state.portalContentAdres = localStorage.portalContentAdres

      state.userSirketler = []


      state.userSirketler.liste = JSON.parse(localStorage.sirketler)
      state.userSirketler.sirketSayisi = state.userSirketler.liste.length
      state.sirketAyarlar.sirket_belgeNoTables = []

       
      state.sirketAyarlar.sirket_belgeNoTables = JSON.parse(localStorage.sirketAyarlar).sirket_belgeNoTables
      state.sirketAyarlar.ililceListesi = JSON.parse(localStorage.sirketAyarlar).ililceListesi
      state.sirketAyarlar.tumCariler = JSON.parse(localStorage.sirketAyarlar).tumCariler
      state.sirketAyarlar.tumFaturalar = JSON.parse(localStorage.sirketAyarlar).tumFaturalar
      state.sirketAyarlar.tumStoklar = JSON.parse(localStorage.sirketAyarlar).tumStoklar
      state.sirketAyarlar.markaListesi = JSON.parse(localStorage.sirketAyarlar).markaListesi
      state.sirketAyarlar.sistemKdvOranlar = JSON.parse(localStorage.sirketAyarlar).sistemKdvOranlar
      state.sirketAyarlar.sistemStokBirimleri = JSON.parse(localStorage.sirketAyarlar).sistemStokBirimleri


      

      if (state.calisilanSirketId == 0) {
        
        state.sirketSecAcilsin = true;
      }
      console.log("init oldu")

    },
    userSirketListesiAl({ state, commit }) {


      var qs = require('qs');
      var data = qs.stringify({
        'maili': state.userMail,
        'mailSifre': state.userSifre,
      });
      var config = {
        method: 'post',
        url: '/api/usersRoute/userSirketler',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',

        },
        data: data
      };



      axios(config).then(res => {

        console.log(res)
        //this.state.userSirketler.liste = []
        state.userSirketler.sirketSayisi = 0
        state.userSirketler.liste = []
        for (let data in res.data.data) {
          state.userSirketler.liste.push(res.data.data[data])

        }

        localStorage.sirketler = JSON.stringify(state.userSirketler.liste)

        if (state.calisilanSirketId == 0) {
          state.sirketSecAcilsin = true
        }

        if (!state.loginGizliAl) {
          setTimeout(() => {
            commit("dashBoardAc")
          }, 200);
        }

      }).catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });

    },

    modalAlertAc(authData) {


      let message = authData.yazi;

      let options = {
        html: true, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        loader: false, // set to true if you want the dailog to show a loader after click on "proceed"
        reverse: false, // switch the button positions (left to right, and vise versa)
        okText: authData.okButton,
        cancelText: authData.canButton,
        animation: 'zoom', // Available: "zoom", "bounce", "fade"
        type: 'basic', // coming soon: 'soft', 'hard'
        verification: 'continue', // for hard confirm, user will be prompted to type this to enable the proceed button
        verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
        clicksCount: 3, // for soft confirm, user will be asked to click on "proceed" btn 3 times before actually proceeding
        backdropClose: authData.distanKapat, // set to true to close the dialog when clicking outside of the dialog window, i.e. click landing on the mask
        customClass: '' // Custom class to be injected into the parent node for the current dialog instance
      };

      Vue.dialog.alert(message, options)
        .then(function () {
          // This will be triggered when user clicks on proceed
        })
        .catch(function () {
          // This will be triggered when user clicks on cancel
        });
    },
    axios_userBilgi({ state, dispatch }) {




      var qs = require('qs');
      var data = qs.stringify({
        'maili': state.userMail,
        'mailSifre': state.userSifre,
      });
      var config = {
        method: 'post',
        url: '/api/usersRoute/userBilgi',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer ' + state.userToken
        },
        data: data
      };


      axios(config).then(res => {


        localStorage.userBilgi = JSON.stringify(res.data.data)
        localStorage.calisilanSirketId = res.data.data.sonCalisilanSirketId

        state.userModel.id = res.data.data.id
        state.userModel.ad = res.data.data.ad
        state.userModel.mail = res.data.data.maili
        state.calisilanSirketId = res.data.data.sonCalisilanSirketId

        localStorage.portalContentAdres = state.postServer + state.portalContentAdres
        // alert("b")
        dispatch("userSirketListesiAl")


      }).catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });



    },
    tokenAl({ commit, dispatch, state }, authData) {


      var qs = require('qs');
      var data = qs.stringify({
        'grant_type': 'password',
        'username': authData.userName,
        'password': authData.Password
      });

      var config = {
        method: 'post',
        url: '/token',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: data
      };

      axios(config)
        .then(res => {

 
          

          state.userToken = res.data.access_token;
          state.userMail = authData.userName
          state.userSifre = authData.Password
          localStorage.token = res.data.access_token;
          localStorage.userMail = authData.userName;
          localStorage.userSifre = authData.Password;
          localStorage.exd = new Date().getTime() + +res.data.expires_in * 500

          dispatch("otomatikLogout", { expiresIn: +res.data.expires_in * 500 })
          this.dispatch("axios_userBilgi")


          state.loadAnimAc = false



        }).catch(error => {
          console.log(error)
          commit("clearToken");
          state.loadAnimAc = false
          setTimeout(() => {


            dispatch("modalAlertAc", {
              yazi: "Geçersiz Kullanıcı Adı yada Şifre",
              okButton: "Tamam",
              canButton: "İptal",
              distanKapat: true
            });
            // commit("modalAlertAc");

          }, 200);

        })
    },
    loginKontrol({ commit, dispatch, state }, authData) {

      // commit("clearToken")

      state.loginGizliAl = false;
      dispatch("tokenAl", { userName: authData.userName, Password: authData.password });



    },
    newUserKontrol({ dispatch, state }, authData) {


      var qs = require("qs");
      var data = qs.stringify({
        'ad': authData.newUserAd,
        'maili': authData.newUserMail,
        'mailSifre': authData.newUserPass
      });

      var config = {
        method: 'post',
        url: '/api/usersRoute/newUser',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: data
      };


      axios(config).then(res => {
        state.loadAnimAc = false


        if (res.data.sonuc) {

          state.userModel.id = res.data.data.id;
          state.userModel.ad = res.data.data.ad;
          state.userModel.maili = res.data.data.maili;
          state.loginGizliAl = true;
          dispatch("tokenAl", { userName: authData.newUserMail, Password: authData.newUserPass });


        } else {
          setTimeout(() => {

            dispatch("modalAlertAc", {
              yazi: res.data.mesaj,
              okButton: "Tamam",
              canButton: "İptal",
              distanKapat: true
            });

          }, 200);
        }

      }).catch(() => {

        state.loadAnimAc = false
        setTimeout(() => {


          dispatch("modalAlertAc", {
            yazi: "Hata..Kullanıcı Oluşturulamadı.Lütfen Tekrar deneyiniz.",
            okButton: "Tamam",
            canButton: "İptal",
            distanKapat: true
          });

        }, 200);
      })



    },
    sifremiUnuttum({ dispatch, state }, authData) {

      var qs = require("qs");
      var data = qs.stringify({
        'maili': authData.maili
      });

      var config = {
        method: 'post',
        url: '/api/usersRoute/sifreAl',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: data
      };


      axios(config).then(res => {
        state.loadAnimAc = false
        setTimeout(() => {


          dispatch("modalAlertAc", {
            yazi: res.data.mesaj,
            okButton: "Tamam",
            canButton: "İptal",
            distanKapat: true
          });

        }, 200);



      }).catch(() => {

        state.loadAnimAc = false
        setTimeout(() => {


          dispatch("modalAlertAc", {
            yazi: "Hata..Şifre Oluşturulamadı.",
            okButton: "Tamam",
            canButton: "İptal",
            distanKapat: true
          });

        }, 200);
      })
    },
    otomatikLogout({ dispatch }, authData) {

      
      setTimeout(() => {

        console.log("logout çalıştı")
        dispatch("logout")
      }, authData.expiresIn);
    },
    sirketCariler: async ({ state }, authData) => {
      //tum carileer
      if (!authData.gizliAl) {
        state.loadAnimAc = true
      }
      var qs = require("qs");
      var config = {
        method: "post",
        url: "/api/carisRoute/tumCariler?cariId=" + authData.cariId,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify({
          userMail: state.userMail,
          userSifre: state.userSifre,
          sirketId: state.calisilanSirketId,
        })
      };

      await axios(config).then((res) => {

        state.loadAnimAc = false
        if (res.data.sonuc) {
          if (authData.cariId == 0) {
            state.sirketAyarlar.tumCariler = res.data.data;
          } else {
            var indi = state.sirketAyarlar.tumCariler.findIndex((o) => o.Id == authData.cariId);
            state.sirketAyarlar.tumCariler[indi] = res.data.data[0]
          }

        }
      }).catch((hata) => {
        state.loadAnimAc = false
      })

      //tum cariler
    },
    sirketStoklar: async ({ state }, authData) => {
      //tum carileer
      if (!authData.gizliAl) {
        state.loadAnimAc = true
      }
      var qs = require("qs");
      var config = {
        method: "post",
        url: "/api/stoksRoute/tumStoklar?stokId=" + authData.stokId,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify({
          userMail: state.userMail,
          userSifre: state.userSifre,
          sirketId: state.calisilanSirketId,
        })
      };

      await axios(config).then((res) => {

        console.log(res)
        state.loadAnimAc = false

        if (res.data.sonuc) {
          if (authData.stokId == 0) {
            state.sirketAyarlar.tumStoklar = res.data.data;
          } else {
            console.log(state.sirketAyarlar.tumStoklar.findIndex((o) => o.Id == authData.stokId))
            var indi = state.sirketAyarlar.tumStoklar.findIndex((o) => o.Id == authData.stokId);
            state.sirketAyarlar.tumStoklar[indi] = res.data.data[0]
          }

        }
      }).catch((hata) => {
        state.loadAnimAc = false
      })

      //tum cariler
    },
    sirketMarkalar: async ({ state }, authData) => {
      //tum carileer
      var qs = require("qs");
      var config = {
        method: "post",
        url: "/api/genel/markaListesi",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify({
          userMail: state.userMail,
          userSifre: state.userSifre,
          sirketId: state.calisilanSirketId,
        })
      };

      await axios(config).then((res) => {



        if (res.data.sonuc) {

          state.sirketAyarlar.markaListesi = res.data.data;


        }
      }).catch((hata) => {

      })

      //tum cariler
    },
    sirketFaturalar: async ({ state }, authData) => {
      //tum carileer
      if (!authData.gizliAl) {
        state.loadAnimAc = true
      }
      var qs = require("qs");
      var config = {
        method: "post",
        url: "/api/faturasRoute/tumFaturalar?faturaId=" + authData.faturaId,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify({
          userMail: state.userMail,
          userSifre: state.userSifre,
          sirketId: state.calisilanSirketId,
        })
      };

      await axios(config).then((res) => {


        state.loadAnimAc = false


        if (res.data.sonuc) {
          state.sirketAyarlar.tumFaturalar = res.data.data;
        }



      }).catch((hata) => {
        state.loadAnimAc = false
      })

      //tum cariler
    },
    sirket_GenelListeler: async ({ state, dispatch }) => {

      //belge no Tabloları

      state.loadAnimAc = true
      var qs = require("qs");

      var config = {
        method: "post",
        url: "/api/faturasRoute/belgeNoTables",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify({
          userMail: state.userMail,
          userSifre: state.userSifre,
          sirketId: state.calisilanSirketId,
        })
      };
      await axios(config).then((response) => {



        state.sirketAyarlar.sirket_belgeNoTables = response.data.data

      }).catch((error) => {

        console.log("Hata" + error)
      })
      //belge no Tabloları

      //kdv oranla

      config.url = "/api/genel/kdvOranlar";
      await axios(config).then((response) => {


        state.sirketAyarlar.sistemKdvOranlar = response.data.data

      }).catch((error) => {

        console.log("Hata. KDV Oran Listesi " + error)
      })


      //kdv oranlar

      //stok birimler

      config.url = "/api/genel/stokBirimleri";
      await axios(config).then((response) => {


        state.sirketAyarlar.sistemStokBirimleri = response.data.data

      }).catch((error) => {

        console.log("Hata. KDV Oran Listesi " + error)
      })


      //stok birimler

      dispatch("sirketMarkalar")
      /*   dispatch("sirketCariler", { cariId: 0 })
        dispatch("sirketStoklar", { stokId: 0 }) */
      // il ilçe listesi
      config.url = "/api/genel/ililceListesi"
      await axios(config).then((response) => {

        state.sirketAyarlar.ililceListesi = response.data.data

      }).catch((error) => {

        console.log("Hata" + error)
      })


      //il ilçe listesi
      // global kap cinsleri
      config.url = "/api/genel/globalKabCinsleri"
      await axios(config).then((response) => {

        state.sirketAyarlar.global_kabCinsleri = response.data.data

      }).catch((error) => {

        console.log("Hata" + error)
      })


      //global kap cinsleri






      //$cookies.set('sirketAyarlar',state.sirketAyarlar);
      localStorage.sirketAyarlar = JSON.stringify(state.sirketAyarlar)
      state.loadAnimAc = false

      /*  var dd = $cookies.get("sirketAyarlar")
       console.log(dd) */

    },

    subeSil({ state, dispatch }, authData) {


    }

  },
  getters: {
    isUserAuth(state) {

      return state.userToken !== null
    },
    paraFormati: () => (value) => {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatPrice: () => (value) => {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }


  },
  plugins: [createPersistedState()],





});


export default store









 
   
 
