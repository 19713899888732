<template>
  <b-navbar type="dark" variant="dark" sticky>
    <b-navbar-nav>
      <b-nav-item href="#" to="/dashboard">M</b-nav-item>


      <b-nav-item-dropdown text="Fatura">
       <template v-slot:button-content>
             <b-icon icon="file-earmark" aria-hidden="true"></b-icon> Fatura
          </template>
        <b-dropdown-item href="#" to="/Fatura/0">
         <b-icon icon="file-earmark" aria-hidden="true"></b-icon>
        Yeni</b-dropdown-item>
        <b-dropdown-item href="#" to="/FaturaListe">
         <b-icon icon="list-stars" aria-hidden="true"></b-icon>
        Liste</b-dropdown-item>
      </b-nav-item-dropdown>
<b-nav-item-dropdown text="Fatura">
       <template v-slot:button-content>
             <b-icon icon="person-fill" aria-hidden="true"></b-icon> Cari
          </template>
        <b-dropdown-item href="#" to="/Cari/0" exact><b-icon icon="file-earmark" aria-hidden="true"></b-icon> Yeni</b-dropdown-item>
        
        <b-dropdown-item href="#" to="/CariListe" exact>  <b-icon icon="list-stars" aria-hidden="true"></b-icon> Liste</b-dropdown-item>
      </b-nav-item-dropdown>


      <b-nav-item-dropdown text="Stok/Hizmet">
       <template v-slot:button-content>
             <b-icon icon="box" aria-hidden="true"></b-icon> Stok/Hizmet
          </template>
        <b-dropdown-item href="#" to="/Stok/0"><b-icon icon="file-earmark" aria-hidden="true"></b-icon> Yeni</b-dropdown-item>
        <b-dropdown-item href="#" to="/Stoklar"><b-icon icon="list-stars" aria-hidden="true"></b-icon>  Liste</b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
export default {};
</script>

<style scoped>
.navbar {
  background-color: #670ca3 !important;
}
</style>
