<template>
  <div>
    <div
      class="loading "
      :class="{
        block: $store.state.loadAnimAc,
        'd-none': !$store.state.loadAnimAc
      }"
    >
      <div class="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
    <app-dash-header v-if="$store.state.userToken !== null"></app-dash-header>
    <transition name="fade" mode="out-in">
      <router-view>
        <component :is="$store.state.aktifComp"> </component>
      </router-view>
    </transition>

    <b-modal v-model="$store.state.calisilanSirketId==0 && $store.state.userToken != null"
      hide-footer
      hide-header-close
      no-close-on-esc
      title="Şirket Seçiniz"
      no-close-on-backdrop>
      <div class="d-block text-center">
        <b-list-group>
          <b-list-group-item
            v-for="item in this.$store.state.userSirketler.liste"
            button
            :key="item.id"
            v-on:click="sirketDegistir(item.id)"
            class="flex-column align-items-start"
          >
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{{ item.sirketAdi }}</h5>
              <small>{{
                item.olusturmaTarihi | moment("DD.MM.YYYY hh:mm")
              }}</small>
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>
    </b-modal>

     <b-sidebar    v-model="$store.state.sideBarOpen" width="35%"
      id="sidebar-backdrop"

      backdrop-variant="dark"
      backdrop
      right
      shadow
      no-header

    >

       <component :is="$store.state.sideBarComp" :fatBilgi="$store.state.faturalar_hizliDuzenleFatura"  >

        </component>
    </b-sidebar>
  
  </div>
</template>

<script>


import dashHeader from "../src/components/genel/dashHeader";
const  faturaHizliDuzenle = resolve=> {
  require.ensure(["../src/components/Fatura/hizliDuzenle.vue"],()=>{
      resolve(require("../src/components/Fatura/hizliDuzenle.vue"));
  },
  "faturaHizliDuzenle")
}
const nakitOdemeAl = resolve=> {
  require.ensure(["../src/components/genel/odemeAl_Nakit.vue"],()=>{
      resolve(require("../src/components/genel/odemeAl_Nakit.vue"));
  },
  "nakitOdemeAl")
}

export default {
  name: "app",
  components: {
    appDashHeader: dashHeader,
      faturaHizliDuzenle,
      nakitOdemeAl

  },
  data() {
    return {
       
    };
  },
  methods: {

    sirketDegistir(value) {
      this.$store.dispatch("sirketDegistir", { id: value });
    }
  },
   created() {
    this.$store.dispatch("initPage");
    
      
  }

};
</script>

<style>
.paraKolonu {
  text-align: right;
}
.ortaKolonUzunluk {
  min-width: 150px;
}
.selector-for-some-widget {
  box-sizing: content-box;
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 1s;
}
.fade-leave {
}
.fade-leave-active {
  transition: opacity 1s;
  opacity: 0;
}
.invalidData {
  border: 1px solid red !important;
}

</style>
