
import Vue from 'vue'
import VueRouter from 'vue-router'

/* import store from './store.js' */
import Home from '../src/components/Login/Home'


const  lostPass = resolve=> {
    require.ensure(["../src/components/Login/lostPass.vue"],()=>{
        resolve(require("../src/components/Login/lostPass.vue"));
    })
}
const  lostPassOnay = resolve=> {
    require.ensure(["../src/components/Login/lostPassOnay.vue"],()=>{
        resolve(require("../src/components/Login/lostPassOnay.vue"));
    })
}
const dash = resolve => {

    require.ensure(["../src/components/genel/dash.vue"],()=>{
        resolve(require("../src/components/genel/dash.vue"));
    })
}
const FaturaListe = resolve => {

    require.ensure(["../src/components/Fatura/FaturaListe.vue"],()=>{
        resolve(require("../src/components/Fatura/FaturaListe.vue"));
    })
}
const Fatura = resolve => {

    require.ensure(["../src/components/Fatura/fatura.vue"],()=>{
        resolve(require("../src/components/Fatura/fatura.vue"));
    })
}

const Stoklar = resolve => {
    require.ensure(["../src/components/Stoklar/stoklar.vue"],()=>{
        resolve(require("../src/components/Stoklar/stoklar.vue"))
    })
}
const Stok = resolve => {
    require.ensure(["../src/components/Stoklar/stok.vue"],()=>{
        resolve(require("../src/components/Stoklar/stok.vue"))
    })
}
const Cari = resolve => {
  require.ensure(["../src/components/Cari/cari.vue"],()=>{
      resolve(require("../src/components/Cari/cari.vue"))
  })
}
const CariListe = resolve => {
  require.ensure(["../src/components/Cari/cariler.vue"],()=>{
      resolve(require("../src/components/Cari/cariler.vue"))
  })
}



Vue.use(VueRouter)

export const router=  new VueRouter({

    routes:[
            {path:'/',component:Home},
            {path:'/FaturaListe',component:FaturaListe},
            {path:'/Cari/:id',component:Cari,name:'Cari'},
            {path:'/CariListe',component:CariListe},
            {path:'/Fatura/:id',component:Fatura},
            {path:'/Stoklar',component:Stoklar},
            {path:'/Stok/:id',component:Stok},
            {path:'/lostPass',component:lostPass},
            {path:'/dashboard',component:dash},
            {path:'/lostPass/:guidi', component:lostPassOnay},
            {
                path:'/dashboard',
                component:dash,
                beforeEnter(to,from,next){

                    if(store.getters.isUserAuth){
                        next()
                        return false;
                    }else{

                       /*  store.dispatch("modalAlertAc",  {
                            yazi: "Oturumunuz Sona Ermiş.Lütfen Giriş Yapınız",
                            okButton:"Tamam",
                            canButton:"İptal",
                            distanKapat  : true
                        }); */

                        next("/")
                        return false;
                    }

                }
            },
            {path:'*' ,component:Home,
                beforeEnter(to,from,next){
                    console.log(from)
                    console.log(to)
                    
                }
        }
    ],
    mode:'history'
})

