<template>
  <section>
    <div class="container" :class="{ active: togleAktif }">
      <div class="user signinBx">
        <div class="imgBx">
          <img src="../../assets/monoryumLogoTam.png" alt="Monoryum E-Fatura" />
        </div>
        <div class="formBx">
          <form action="" autocomplete="off">
            <input
              type="email"
              v-model="loginVal.userName"
              @blur="$v.loginVal.userName.$touch()"
              :class="{ invalidData: $v.loginVal.userName.$error }"
              placeholder="Kullanıcı Adı"
            />

            <input
              type="password"
              v-model="loginVal.password"
              @blur="$v.loginVal.password.$touch()"
              :class="{ invalidData: $v.loginVal.password.$error }"
              placeholder="Şifre"
            />
            <button
              type="button"
              :disabled="$v.loginVal.$invalid"
              @click="loginKontrol"
              class="btn btn-block w-100 "
            >
              Monoryuma Giriş
            </button>

            <p class="signup" style="text-align:center;">
              <a href="#" @click.prevent="togleAktif = !togleAktif" style="color:darkgray;text-decoration:none;" 
                > Yeni Kayıt Oluşturmak istiyorum...
             <br>
            <span style="font-size:16px;color:blue;">YENİ KAYIT</span> </a>
              
             
            </p>
            <p class="lostPassDiv">
              <a href="#" @click="$router.push('lostPass')">ŞİFREMİ UNUTTUM</a>
            </p>
          </form>
        </div>
      </div>
      <div class="user signupBx">
        <div class="formBx">
          <form action="" autocomplete="off">
            <h2>Yeni Kayıt</h2>
            <input
              type="text"
              v-model="newUserVal.newUserAd"
              @blur="$v.newUserVal.newUserAd.$touch()"
              :class="{ invalidData: $v.newUserVal.newUserAd.$error }"
              placeholder="Firma Adı"
            />
            <input
              type="email"
              v-model="newUserVal.newUserMail"
              @blur="$v.newUserVal.newUserMail.$touch()"
              :class="{ invalidData: $v.newUserVal.newUserMail.$error }"
              placeholder="Email Address"
            />
            <input
              type="password"
              v-model="newUserVal.newUserPass"
              @blur="$v.newUserVal.newUserPass.$touch()"
              :class="{ invalidData: $v.newUserVal.newUserPass.$error }"
              placeholder="Şifre"
            />
            <span
              v-if="
                newUserVal.newUserPass.length > 0 &&
                  newUserVal.newUserPass.length < 6
              "
              class="form-text text-danger"
              >Şifreniz min.
              {{ $v.newUserVal.newUserPass.$params.minLength.min }} karakterli
              olmalıdır.</span
            >
            <button
              type="button"
              :disabled="$v.newUserVal.$invalid"
              @click="newUserKontrol"
              class="btn btn-block w-100 "
            >
              KAYDET
            </button>
            <p class="signup">
              Zaten Bir Hesabım Var ?<br>
              <a href="#" @click.prevent="togleAktif = !togleAktif"
                >Monoryum'a Giriş.</a
              >
            </p>
          </form>
        </div>
        <div class="imgBx ">
          <img src="../../assets/monoryumLogoTam.png" alt="Monoryum E-Fatura" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      togleAktif: false,
      loginVal: {
        userName: null,
        password: null
      },
      newUserVal: {
        newUserAd: "",
        newUserMail: "",
        newUserPass: ""
      },
      errorLogin: false
    };
  },
  methods: {
    loginKontrol() {
      this.$store.state.loadAnimAc = true;
      setTimeout(() => {
        this.$store.dispatch("loginKontrol", {
          userName: this.loginVal.userName,
          password: this.loginVal.password
        });
      }, 1000);
    },
    newUserKontrol() {
      this.$store.state.loadAnimAc = true;
      let data = {};
      data = this.newUserVal;
      console.log(data);

      setTimeout(() => {
        this.$store.dispatch("newUserKontrol", { ...this.newUserVal });
      }, 1000);
    }
  },

  validations: {
    loginVal: {
      userName: {
        required,
        email
      },
      password: {
        required
      }
    },
    newUserVal: {
      newUserAd: {
        required
      },
      newUserMail: {
        required,
        email
      },
      newUserPass: {
        required,
        minLength: minLength(6)
      }
    }
  },
  created() {
    this.loginVal.userName = null;
    this.loginVal.password = null;
  }
};
</script>

<style scoped>


section {
  position: relative;
  min-height: 100vh;
  background-color: #670ca3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

section .container {
  position: relative;
  width: 800px;
  height: 500px;
  background: #fff;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border-radius: 20px;
}

section .container .user {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

section .container .user .imgBx {
  position: relative;
  width: 50%;
  height: 100%;
  background: white;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

section .container .user .imgBx img {
  width: 50%;
  height: auto !important;
}

section .container .user .formBx {
  position: relative;
  width: 50%;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  transition: 0.5s;
}

section .container .user .formBx form h2 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
  color: #555;
}

section .container .user .formBx form input {
  position: relative;
  width: 100%;
  padding: 10px;
  background: #f5f5f5;
  color: #333;
  border: none;
  outline: none;
  box-shadow: none;
  margin: 8px 0;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 300;
}

section .container .user .formBx form button[type="button"] {
  background: #670ca3;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  transition: 0.5s;
}
section .container .user .formBx form button[type="submit"] :disabled {
  background: lightgray;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  transition: 0.5s;
}
section .container .user .formBx form .signup {
  position: relative;
  margin-top: 20px;
  font-size: 12px;
  letter-spacing: 1px;
  color: #555;
  text-transform: uppercase;
  font-weight: 300;
}
section .container .user .formBx form .lostPassDiv {
  position: absolute;
  bottom: 0;
  right: 20px;
  font-size: 12px;
  letter-spacing: 1px;
  color: #555;
  text-transform: uppercase;
  font-weight: 300;
}
section .container .user .formBx form .lostPassDiv a {
  font-weight: 600;
  text-decoration: none;
  color: #677eff;
}

section .container .user .formBx form .signup a {
  font-weight: 600;
  text-decoration: none;
  color: #677eff;
}

section .container .signupBx {
  pointer-events: none;
}

section .container.active .signupBx {
  pointer-events: initial;
}

section .container .signupBx .formBx {
  left: 100%;
}

section .container.active .signupBx .formBx {
  left: 0;
}

section .container .signupBx .imgBx {
  left: -100%;
}

section .container.active .signupBx .imgBx {
  left: 0%;
}

section .container .signinBx .formBx {
  left: 0%;
}

section .container.active .signinBx .formBx {
  left: 100%;
}

section .container .signinBx .imgBx {
  left: 0%;
}

section .container.active .signinBx .imgBx {
  left: -100%;
}

@media (max-width: 991px) {
  section .container {
    max-width: 400px;
  }

  section .container .imgBx {
    display: none;
  }

  section .container .user .formBx {
    width: 100%;
  }
}
</style>
